.sidebarrow{
    display: flex;
    align-items: center;
    margin-top: 0.2vh;
}
.sidebarrow-icon {
    color: #606060;
    font-size: 2vw !important;
    margin-left: 10%;
}
.sidebarrow-title {
    flex: 0.5;
    text-align: left;
    margin-left: 2vw;
    
    font-size: 1.2vw;
    font-weight: 600;
}
.sidebarrow:hover {
    background-color: lightgray;
    cursor: pointer;
}
.sidebarrow:hover > .sidebarrow-icon {
    color: red;
}
.sidebarrow:hover > .sidebarrow-title {
    font-weight: bold;
}

.sidebarrow.selected {
    background-color: lightgray ;
}