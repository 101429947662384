.recommendedvideo {
    flex: 0.8;
    
    background-color: #f9f9f9;
    padding-bottom: 0;
    padding: 4vw 2vw;
    align-items: left;
    
}
.recommendedvideo h2 {
    margin-left: 0.5vw;
    margin-bottom: 2vw;
    text-align: left;
}
.search-result {
    display: center;
    flex-direction: row;
    width: 70%;
    

}
.search-container{
    display: left;
    align-items: left;
    margin: 0%;

}

.recommendedvideo-videos{
    display: left;
    flex-direction: row;
    
   


}
.search-result-details h3 {
    text-align: left;
    font-size: 1.8vw;

}
.search-result-details p {
    text-align: left;
    font-size: 1.2vw;
    

}
