.sidebar {
    flex: 0.2;
    
}
.sidebar > hr {
    height: 0.2vw;
    border: 0;
    background-color: lightgray;
    margin-top: 1vw;
    margin-bottom: 1vw;
}