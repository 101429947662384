.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2vw;
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: white;
}
.header-logo{
    height: 2.5vw;
    object-fit: contain;
    margin-left: 2vw;
}
.header-input {
    display: flex;
    align-items: center;
    border: 1px solid lightgray;
    width: 40vw;
}
.header-icons {
    display: flex;
    align-items: center;
   
}
.header-input > input {
    flex: 1;
    border: none;
}
.header-input-button {
    width: 4vw!important;
    background-color: #fafafa;
    border-left: 1px solid lightgray;
    color: gray;

}
.header-icon {
    margin-right: 0.8vw;
    
}

@media (max-width: 500px) {
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 2vw;
        position: sticky;
        top: 0;
        z-index: 100;
        background-color: white;
        height: 5vh;
    }
    .header-logo{
        height: 4vw;
        object-fit: contain;
        margin-left: 0vw;
    }
    .header-input {
        display: flex;
        align-items: center;
        border: 1px solid lightgray;
        width:50vw;
        
    }
    .header-icons {
        display: flex;
        align-items: center;
        
        
    }
    .header-input > input {
        flex: 1;
        border: none;
    }
    .header-input-button {
        width: 6vw!important;
        background-color: #fafafa;
        border-left: 1px solid lightgray;
        color: gray
    }
    .header-icon {
        margin-right: 0.8vw; 
    }
  

}